<!--
 * @Author: your name
 * @Date: 2020-12-01 15:24:15
 * @LastEditTime: 2024-11-05 15:29:04
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: \slide-tct\src\views\Home.vue
-->
<template>
  <div id="home">
    <Header></Header>

    <full-page :options="options">
      <!-- 每个需要全屏滚动的页面都需要包在<div class="section"></div>中 -->
      <div class="section viewer-one">
        <div class="image">
          <div class="title wow animate__animated animate__fadeInUp">
            <div class="focus">“AI+数字病理”</div>
            <div class="ordinary">的探索者和引领者</div>
          </div>
          <div class="subheading wow animate__animated animate__fadeInUp">
            以创新科技驱动医学诊断，去探索生命中更多的未知，从而创造更加美好的健康生活
          </div>
          <div class="keywords wow animate__animated animate__fadeInUp">
            多学科 多生态 多应用
          </div>
        </div>
      </div>
      <div class="section viewer-two">
        <div class="image">
          <Solution></Solution>
        </div>
      </div>
      <div class="section viewer-three">
        <div class="image">
          <News></News>
        </div>
      </div>
      <div class="section viewer-five">
        <div class="image">
          <div class="abount-box">
            <div class="left">
              <div class="title">学术园地</div>
              <div class="description">探究行业热点, 掌握最新动态</div>
              <div class="arr-right">
                <i
                  class="iconfont icon-youjiantou"
                  @click="tapPages('/science')"
                ></i>
              </div>
            </div>
            <div class="right">
              <div class="decipher">
                <div class="literature">
                  <div class="title">文献解读</div>
                  <ul class="decipher-list">
                    <li
                      v-for="item in decipherList"
                      :key="item.id"
                      @click="tapArticle(item.id)"
                    >
                      <div class="topic wow animate__animated animate__fadeInUp">{{ item.title }}</div>
                      <div class="subtitle wow animate__animated animate__fadeInUp">{{ item.summary }}</div>
                    </li>
                  </ul>
                </div>
                <div class="foot-img">
                  <img src="../../assets/image/home/description.png" alt="" />
                </div>
              </div>
              <div class="doctor">
                <div class="video">
                  <div class="title">学术视频</div>
                  <ul class="video-box">
                    <li class="video-item">
                      <div
                        class="video-img"
                        :style="{
                          'background-image': `url(${videoList.titleImage}`,
                        }"
                      ></div>
                      <div class="title-box">
                        <div class="title" @click="tapVideo(videoList.id)">
                          {{ videoList.title }}
                        </div>
                        <div class="time">
                          {{
                            videoList.createTime
                              ? videoList.createTime.split(" ")[0]
                              : ""
                          }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="foot-img">
                  <img src="../../assets/image/home/doctor.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section viewer-four">
        <div class="image">
          <div class="abount-box">
            <div class="left">
              <div class="title">关于我们</div>
              <div class="description">品信生物科技，快速解决行业疑难问题</div>
              <div class="arr-right">
                <i
                  class="iconfont icon-youjiantou"
                  @click="tapPages('/about')"
                ></i>
              </div>
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div>
      <div class="section contact_footer">
        <div class="footer">
          <Footer></Footer>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import Solution from "./componets/Solution.vue";
import News from "./componets/News.vue";
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
import api from "../../api/api";
export default {
  props: {},
  components: {
    Header,
    Solution,
    News,
    Footer,
  },
  data() {
    return {
      bannerUrl: require("@/assets/image/home/banner.jpg"),
      decipherList: [],
      videoList: {},
      options: {
        // 为每个section设置背景色
        //sectionsColor: ["#f00","#0f0","#00f"],
        //用来控制slide幻灯片的箭头，设置为false，两侧的箭头会消失
        //controlArrows: false,
        //每一页幻灯片的内容是否垂直居中
        //verticalCentered: false,
        //字体是否随着窗口缩放而缩放
        //resize: true,
        //页面滚动速度
        //scrollingSpeed: 700,
        //定义锚链接，用户可以快速打开定位到某一页面；不需要加"#"，不要和页面中任意的id和name相同
        //anchors: ["page1","page2","page3"],
        //是否锁定锚链接
        //lockAnchors: true,
        //定义section页面的滚动方式，需要引入jquery.easings插件
        //easing:,
        //是否使用css3 transform来实现滚动效果
        //css3: false,
        //滚动到最顶部后是否连续滚动到底部
        //loopTop: true,
        //滚动到最底部后是否连续滚动到顶部
        //loopBottom: true,
        //横向slide幻灯片是否循环滚动
        //loopHorizontal: false,
        //是否循环滚动，不会出现跳动，效果很平滑
        //continuousVertical: true,
        //是否使用插件滚动方式，设为false后，会出现浏览器自带的滚动条，将不会按页滚动
        //autoScrolling: false,
        //是否包含滚动条，设为true，则浏览器自带的滚动条会出现，页面还是按页滚动，但是浏览器滚动条默认行为也有效
        //scrollBar: true,
        //设置每个section顶部的padding，当我们要设置一个固定在顶部的菜单、导航、元素等时使用
        //paddingTop: "100px",
        //设置每个section底部的padding，当我们要设置一个固定在底部的菜单、导航、元素等时使用
        //paddingBottom: "100px",
        //固定的元素，为jquery选择器；可用于顶部导航等
        //fixedElements: ".nav",
        //是否可以使用键盘方向键导航
        //keyboardScrolling: false,
        //在移动设置中页面敏感性，最大为100，越大越难滑动
        //touchSensitivity: 5,
        //设为false，则通过锚链接定位到某个页面不再有动画效果
        //animateAnchor: false,
        //是否记录历史，可以通过浏览器的前进后退来导航
        //recordHistory: true,
        //绑定菜单，设定相关属性和anchors的值对应后，菜单可以控制幻灯片滚动
        //menu: '.nav',
        //是否显示导航，设为true会显示小圆点作为导航
        navigation: true,
        //导航小圆点的位置，可以设置为left或者right
        navigationPosition: "right",
        //鼠标移动到小圆点上时显示出的提示信息
        // navigationTooltips: ["第一页", "第二页", "第三页", "第四页", "第五页"],
        //是否显示当前页面小圆点导航的提示信息，不需要鼠标移上
        // showActiveTooltip: true,
        //是否显示横向幻灯片的导航
        //slidesNavigation: true,
        //横向幻灯片导航的位置，可以为top或者bottom
        //slidesNavPosition: bottom,
        //内容超过满屏时是否显示滚动条，需要jquery.slimscroll插件
        //scrollOverflow: true,
        //section选择器
        //sectionSelector: ".section",
        //slide选择器
        //slideSelector: ".slide"
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        afterLoad: this.afterLoad,
        scrollOverflow: true,
        scrollBar: false,
        menu: "#menu",
        // navigation: true,
        // anchors: ['page1', 'page2', 'page3'],
        // sectionsColor: [
        //   "#41b883",
        //   "#ff5f45",
        //   "#0798ec",
        //   "#fec401",
        //   "#1bcee6",
        //   "#ee1a59",
        //   "#2c3e4f",
        //   "#ba5be9",
        //   "#b4b8ab",
        // ],
      },
    };
  },
  created() {
    this.getDecipherList();
    this.getVideoList();
  },
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    // 分页获取文献列表
    getDecipherList(page = 1) {
      api
        .getDecipherByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            if (result.length <= 2) {
              this.decipherList = result;
            } else {
              this.decipherList = result.slice(0, 2);
            }
            console.log(this.decipherList);
          }
        })
        .catch((error) => {});
    },
    // 分页获取视频列表
    getVideoList(page = 1) {
      api
        .getVideoByPage(page)
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 200) {
            let result = res.data.models;
            result.forEach((item) => {
              let times = item.createTime ? item.createTime.split(" ")[0] : "";
              let moment = times ? times.split("-") : [];
              item.year = moment[0];
              item.month = moment[1];
              item.day = moment[2];
            });
            this.videoList = result[0];
            // console.log(this.videoList);
          }
        })
        .catch((error) => {});
    },
    tapPages(path) {
      this.$router.push(path);
    },
    // 点击文献解读
    tapArticle(id) {
      this.$router.push({
        path: `/decipherDetail/${id}`,
      });
    },
    // 点击学术视频
    tapVideo(id) {
      this.$router.push({
        path: `/doctorDetail/${id}`,
      });
    },
    afterLoad(anchorLink, index) {
      console.log(index);
      if (index.index == 0) {
      } else if (index.index == 1) {
      } else if (index.index == 2) {
      } else if (index.index == 3) {
      } else if (index.index == 4) {
      }
    },
  },
};
</script>

<style lang="scss">
#home {
  position: relative;
  width: 100%;
  height: 100%;
  .section {
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: 50%;
    position: relative;
    min-height: 100vh;
    height: 100vh;
  }
  .image {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
  }
  @keyframes scaleDraw {
    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      // transform: scale(1.1); /*开始为原始大小*/
      background-size: 110%;
    }
    // 50% {
    //   // transform: scale(1.1);
    //   background-size: 110%;
    // }
    100% {
      // transform: scale(1);
      background-size: 102%;
    }
  }
  .viewer-one {
    .image {
      background-image: url("../../assets/image/home/banner.jpg");
      // transform: scale(1.1);
      // transition: all 3s ease;
      // background-size: 110%;
      animation-name: scaleDraw; /*关键帧名称*/
      animation-timing-function: ease; /*动画的速度曲线*/
      animation-duration: 2s; /*动画所花费的时间*/
      // background-size: 100%;
      .title {
        position: absolute;
        top: 25%;
        left: 8%;
        display: flex;
        color: #212121;
        align-items: center;
        .focus {
          font-family: "OPlusSans3-Bold";
          font-size: 2.4rem;
          color: #0b847b;
        }
        .ordinary {
          font-size: 2.4rem;
          margin-left: 1rem;
          font-family: "Microsoft YaHei";
        }
      }
      .subheading {
        position: absolute;
        top: 34%;
        left: 8%;
        font-size: 1.6rem;
        color: #212121;
        width: 38%;
        line-height: 1.5;
        font-family: "Microsoft YaHei";
        opacity: 0.9;
      }
      .keywords {
        position: absolute;
        top: 55%;
        left: 8%;
        font-family: "OPlusSans3-Medium";
        color: #212121;
        font-size: 3rem;
      }
    }
  }
  .viewer-two {
    .image {
      background-image: url("../../assets/image/home/solution.png");
      padding: 6.25rem 8% 1.25rem;
    }
  }
  .viewer-three {
    .image {
      background-image: url("../../assets/image/home/dynamic.png");
    }
  }

  .viewer-four {
    .image {
      background: #fff;
      padding: 10rem 8% 3.75rem;
      .abount-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-image: url("../../assets/image/home/about.jpg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        .left {
          width: 20%;
          .title {
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: "SourceHanSansSC-Medium";
          }
          .description {
            font-size: 1.2rem;
            width: 11.875rem;
            color: #444;
            margin-bottom: 4rem;
            font-family: "OPlusSans3-Regular";
          }
          .arr-right {
            i {
              font-size: 6rem;
              color: #0b847b;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 1;
        }
      }
    }
  }
  .viewer-five {
    .image {
      background: #fff;
      padding: 10rem 8% 3.75rem;
      .abount-box {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background-image: url("../../assets/image/home/science.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        display: flex;
        .left {
          width: 22%;
          .title {
            font-size: 2rem;
            margin-bottom: 1rem;
            font-family: "SourceHanSansSC-Medium";
          }
          .description {
            font-size: 1.2rem;
            width: 11.875rem;
            color: #444;
            margin-bottom: 4rem;
            font-family: "OPlusSans3-Regular";
          }
          .arr-right {
            i {
              font-size: 6rem;
              color: #0b847b;
              cursor: pointer;
            }
          }
        }
        .right {
          flex: 1;
          display: flex;
          .decipher {
            width: 47%;
            height: 100%;
            background-color: #fff;
            margin-right: 6%;
            border: 0.0625rem solid #ededed;
            border-radius: 1.5rem;
            box-shadow: 0 0 10px 2px #eee;
            display: flex;
            flex-direction: column;
            .literature {
              flex: 1;
              padding: 1.5rem;
              .title {
                padding-top: 2rem;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 2rem;
              }
              .decipher-list {
                li {
                  position: relative;
                  margin-bottom: 3rem;
                  cursor: pointer;
                  &:first-child {
                    &::after {
                      content: "";
                      position: absolute;
                      left: 0;
                      bottom: -1.5rem;
                      width: 50%;
                      height: 5px;
                      background-color: #0b847b;
                    }
                  }
                  .topic {
                    font-size: 1.2rem;
                    font-family: "SourceHanSansSC-Medium";
                    margin-bottom: 0.5rem;
                  }
                  .subtitle {
                    font-size: 1rem;
                    color: #666666;
                    font-family: "OPlusSans3-Regular";
                    line-height: 1.8;
                  }
                }
              }
            }
            .foot-img {
              img {
                width: 100%;
              }
            }
          }
          .doctor {
            width: 47%;
            height: 100%;
            background-color: #fff;
            border: 0.0625rem solid #ededed;
            border-radius: 1.5rem;
            box-shadow: 0 0 10px 2px #eee;
            display: flex;
            flex-direction: column;
            .video {
              flex: 1;
              padding: 1.5rem;
              .title {
                padding-top: 2rem;
                font-size: 1.5rem;
                margin-bottom: 1rem;
                font-family: "SourceHanSansSC-Medium";
              }
              .video-box {
                padding: 0 5%;
                display: flex;
                flex-wrap: wrap;
                .video-item {
                  border: 1px solid #ededed;
                  cursor: pointer;
                  .video-img {
                    height: 13rem;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 102%;
                    &:hover {
                      animation-name: scaleDraw; /*关键帧名称*/
                      animation-timing-function: ease; /*动画的速度曲线*/
                      animation-duration: 2s; /*动画所花费的时间*/
                    }
                  }

                  @keyframes scaleDraw {
                    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
                    0% {
                      background-size: 110%;
                    }
                    100% {
                      background-size: 102%;
                    }
                  }

                  // img {
                  //   width: 100%;
                  // }
                  .title-box {
                    padding: 1.25rem;
                    .title {
                      font-size: 1.2rem;
                      font-family: "SourceHanSansSC-Medium";
                      margin-bottom: 0.625rem;
                    }
                    .time {
                      color: #666666;
                      font-family: "OPlusSans3-Regular";
                    }
                  }
                }
              }
            }
            .foot-img {
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .contact_footer {
    background: #f5f5f5;
    height: 28.125rem !important;
    min-height: initial !important;
    .fp-tableCell {
      height: 9.375rem !important;
      .footer {
        width: 100%;
        height: 100%;
        padding: 6.25rem 8% 3.125rem;
      }
    }
  }
  .fp-scroller {
    height: 100%;
  }
}
</style>
